.App-header {
  align-items: flex-start;
  background-color: #dedede;
  color: #111111;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: flex-start;
  min-height: 10vh;
  padding-left: 15px;
  text-align: center;
}

.App-header > h1 {
  margin: 0.4em 0;
}

.App-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 600px;
  padding: 0px 15px;
}

.App-body p,
.App-body ul {
  margin-top: 0;
}

.App-body ul > li {
  margin-bottom: 0.25em;
}

.book-title, .note {
  font-style: italic;
}

img {
  margin-bottom: 5px;
  max-width: 400px;
}

@media (max-width: 500px) {
  img {
    max-width: 90%;
  }
}

#page-title > a {
  color: inherit;
  text-decoration: none;
}
